.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ui.input.right > input {
  text-align: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ui.dividing.header {
  font-size: 20px ;
}
.ui.secondary.menu  .item {
  font-size: 14px ;  
}
.ui.attached.tabular.menu {
  font-size: 16px;
}
.ui.button {
  font-size: 16px;
}
.ui.blue.label {
  font-size: 14px; 
}
.ui.green.label {
  font-size: 14px;
}
.ui.red.label {
  font-size: 14px;
}
.ui.buttons>.ui.button:not(.basic):not(.inverted){
  font-size: 16px; 
}
.ui.table {
  font-size: 16px;
}
.ui.form .inline.fields .field {
  font-size: 16px; /*ค้นหา*/ 
}
.ui.form .field {
  font-size: 16px;
}

.ui.dropdown .menu>.item {
  font-size: 16px;
}
.ui.checkbox {
  font-size: 16px;
}
.ui.segment{
  font-size: 16px; /* ฝากเงิน*/
}
.ui.modal>.header {
  font-size: 18px;
}
.ui.modal>.content {
  font-size: 16px;
}

.ui.horizontal.divider {
  font-size: 16px;
}
.ui.list {
  font-size: 16px;
} 

.Toastify__toast-body {
  font-size: 14px; /* alert*/
}
.ui.bottom.center.popup {
  font-size: 16px;
}
.ui.top.left.popup {
  font-size: 16px;
}



